import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Container from "react-bootstrap/Container";
import Figure from "react-bootstrap/Figure";
import { API_PALLAS_URL } from "../ducks/consts";
import { Typography } from "@material-ui/core";
import Loading from "../components/Loading";
import toDecimalDisplay from "../utils/toDecimalDisplay";
import { Table } from "react-bootstrap";
import toPriceDisplay from "../utils/toPriceDisplay";

const ComandaDetalhes = ({ comanda }) => {
  return (
    <Container className="m-0 p-0">
      <Table className="table-status">
        <thead>
          <tr>
            <th className="m-0 p-0">PRODUTO</th>
            <th className="m-0 p-0">QTD.</th>
            <th className="m-0 p-0 text-right">R$ TOTAL</th>
          </tr>
        </thead>
        <tbody>
          {comanda.itens.map((item, index) => (
            <tr key={`cached-product-${item.id}-${index}`}>
              <td className="m-0 pt-1 pb-1 px-0">
                <small>{item.desc_prod}</small>
              </td>
              <td className="m-0 pt-1 pb-1 px-0">
                <small>{item.qtd_it}</small>
              </td>
              <td className="m-0 pt-1 pb-1 px-0 text-right">
                <small>{toDecimalDisplay(item.total_alt_it)}</small>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <div className="pr-3 pl-3">
        <p className="d-flex justify-content-between">
          <span>
            <strong>TOTAL PRODUTOS</strong>
          </span>
          <span>
            <strong>{toPriceDisplay(comanda.total_bruto_ped)}</strong>
          </span>
        </p>
      </div>

      <div className="pr-3 pl-3">
        <p className="d-flex justify-content-between">
          <span>
            <strong>TOTAL SERVIÇO</strong>
          </span>
          <span>
            <strong>{toPriceDisplay(comanda.servico_vlr_ped)}</strong>
          </span>
        </p>
      </div>

      <div className="pr-3 pl-3">
        <p className="d-flex justify-content-between">
          <span>
            <strong>TOTAL</strong>
          </span>
          <span>
            <strong>{toPriceDisplay(comanda.total)}</strong>
          </span>
        </p>
      </div>

      {(!comanda.pagamentos || comanda.pagamentos.length === 0) && (
        <Typography variant="body1" align="center" className="mb-3">
          NENHUM PAGAMETNO
        </Typography>
      )}

      {comanda.pagamentos && comanda.pagamentos.length > 0 && (
        <Table className="table-status">
          <tbody>
            {comanda.pagamentos.map((pag, index) => (
              <tr key={`cached-product-${pag.id}-${index}`}>
                <td className="m-0 pt-1 pb-1 px-0">{pag.nome}</td>
                <td className="m-0 pt-1 pb-1 px-0 text-right">
                  {toPriceDisplay(pag.valor)}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      <Typography variant="body1" align="center" className="mb-3">
        FIM
      </Typography>
    </Container>
  );
};

export default function InlocoStatusScreen() {
  const shop = useSelector((state) => state.shop);
  const { tableId } = shop;
  const [name, codigo, id] = tableId.split(`-`);
  const [loading, setLoading] = React.useState(true);
  const [comanda, setComanda] = React.useState(null);

  useEffect(() => {
    const fetchComanda = async (shopId, comandaId, comandaCodigo) => {
      try {
        const response = await fetch(
          `${API_PALLAS_URL}/pdv_local/comandaAbertaCDP?lojaId=${shopId}&comandaId=${comandaId}&comandaCodigo=${comandaCodigo}`
        );
        const json = await response.json();
        setComanda(json);
      } catch (err) {
        console.error(err);
        setComanda(null);
      } finally {
        setLoading(false);
      }
    };

    fetchComanda(shop.id, id, codigo);
  }, [shop.id, id, codigo]);

  return (
    <Container>
      <div className="d-flex align-items-center">
        <Figure.Image
          src={shop.pictureUrl}
          alt="Logo da loja"
          className="my-4 fixed-logo-figure"
          width="150"
          height="150"
          roundedCircle
        />
        <Typography variant="h3" align="center" className="w-100">
          <strong>{name.toUpperCase()}</strong>
          <br />
          <strong>{codigo}</strong>
        </Typography>
      </div>
      {loading && <Loading message="Carregando..." />}
      {!loading && comanda === null && (
        <Typography variant="body1" color="error" align="center">
          {`Ocorreu um erro ao carragar`}
        </Typography>
      )}
      {!loading && comanda !== null && <ComandaDetalhes comanda={comanda} />}
    </Container>
  );
}
